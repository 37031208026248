import React, { useContext } from 'react'
import { ThemeContext } from 'f-web-app'
import { useSelector } from 'react-redux'
import { dispatch } from 'f-web-app/store'
import { FView, FText, FButton } from 'f-web-app/components'

export default function AddTip() {
  const { themeColors } = useContext(ThemeContext)
  const subTotal = useSelector(dispatch.user.getCartSubTotal)
  const tax = useSelector(dispatch.user.getCartTax)
  const tipSelectionIndex = useSelector(dispatch.user.getTipSelectionIndex)
  return (
    <FView row alignCenter>
      <FText h7 bold grey>
        Add a tip:
      </FText>
      <FView fill maxWidth={15} />
      <FButton
        onClick={() => {
          if (tipSelectionIndex === 0) {
            dispatch.user.setTipSelectionIndex(null)
          } else {
            dispatch.user.setTipSelectionIndex(0)
          }
        }}
      >
        <FView w={70} h={50} br={8} bc={tipSelectionIndex === 0 ? themeColors.primary : themeColors.grey} bw={1} center>
          <FText h7 bold color={tipSelectionIndex === 0 ? themeColors.primary : themeColors.grey}>
            {subTotal + tax < 5 ? '$0.50' : '10%'}
          </FText>
        </FView>
      </FButton>
      <FView fill maxWidth={15} />
      <FButton
        onClick={() => {
          if (tipSelectionIndex === 1) {
            dispatch.user.setTipSelectionIndex(null)
          } else {
            dispatch.user.setTipSelectionIndex(1)
          }
        }}
      >
        <FView w={70} h={50} br={8} bc={tipSelectionIndex === 1 ? themeColors.primary : themeColors.grey} bw={1} center>
          <FText h7 bold color={tipSelectionIndex === 1 ? themeColors.primary : themeColors.grey}>
            {subTotal + tax < 5 ? '$1.00' : '15%'}
          </FText>
        </FView>
      </FButton>
      <FView fill maxWidth={15} />
      <FButton
        onClick={() => {
          if (tipSelectionIndex === 2) {
            dispatch.user.setTipSelectionIndex(null)
          } else {
            dispatch.user.setTipSelectionIndex(2)
          }
        }}
      >
        <FView w={70} h={50} br={8} bc={tipSelectionIndex === 2 ? themeColors.primary : themeColors.grey} bw={1} center>
          <FText h7 bold color={tipSelectionIndex === 2 ? themeColors.primary : themeColors.grey}>
            {subTotal + tax < 5 ? '$1.50' : '20%'}
          </FText>
        </FView>
      </FButton>
    </FView>
  )
}
