import React, { useContext } from 'react'
import { css } from 'emotion'
import { FText, FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { dispatch } from 'f-web-app/store'

const SummaryTotalView = ({ subTotal, tax, total, tipAmount, orderType, deliveryFee }) => {
  const { themeColors } = useContext(ThemeContext)
  const rewardPoints = useSelector(dispatch.user.getCartRewardPoints)
  return (
    <>
      <FView ml="1rem" mr="5rem">
        <div className={styles.totalRow}>
          <FText h7 grey bold>
            SubTotal:
          </FText>
          <FText h7 grey bold>
            ${subTotal.toFixed(2)}
          </FText>
        </div>
        {orderType === 'Delivery' && deliveryFee > 0 && (
          <>
            <FView size={'1rem'} />
            <div className={styles.totalRow}>
              <FText h7 grey bold>
                Delivery Fee:
              </FText>
              <FText h7 grey bold>
                ${deliveryFee.toFixed(2)}
              </FText>
            </div>
          </>
        )}
        {tax > 0 && (
          <>
            <FView size={'1rem'} />
            <div className={styles.totalRow}>
              <FText h7 grey bold>
                GST (5%):
              </FText>
              <FText h7 grey bold>
                ${tax.toFixed(2)}
              </FText>
            </div>
          </>
        )}
        {tipAmount > 0 && (
          <>
            <FView size={'1rem'} />
            <div className={styles.totalRow}>
              <FText h7 grey bold>
                Tip:
              </FText>
              <FText h7 grey bold>
                ${tipAmount.toFixed(2)}
              </FText>
            </div>
          </>
        )}
      </FView>
      <FView h={1} mv={'1rem'} bg={themeColors.lightGrey} />
      <FView ml="1rem" mr="5rem" row justifyBetween>
        <FText bold black h6>
          Total:
        </FText>
        <FText h6 primary bold>
          ${total.toFixed(2)}
        </FText>
      </FView>
      <FView h={1} mv={'1rem'} bg={themeColors.lightGrey} />
      {rewardPoints > 0 && (
        <>
          <FView ml="1rem" mr="5rem" row justifyBetween>
            <FView row>
              <FontAwesomeIcon icon="award" style={{ fontSize: '2rem' }} color={themeColors.lightGrey} />
              <FView size={'1rem'} />
              <FText h7 lightGrey bold>
                Rewards:
              </FText>
            </FView>
            <FText h7 lightGrey bold>
              {rewardPoints} points
            </FText>
          </FView>
          <FView h={1} mv={'1rem'} bg={themeColors.lightGrey} />{' '}
        </>
      )}
    </>
  )
}

const styles = {
  totalRow: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
}

export default SummaryTotalView
