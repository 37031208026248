import React, { useContext } from 'react'
import { Space, FText, FView } from 'f-web-app/components'
import { css } from 'emotion'
import { COLOR } from 'f-web-app/styles'
import { ThemeContext } from 'f-web-app'

const OrderDetailsView = ({
  orderNumber,
  CloseButtonElement,
  OrderStatusElement,
  ProductListElement,
  RewardListElement,
  DiscountElement,
  AddedChargesElement,
  subTotalFormatted,
  taxFormatted,
  totalFormatted,
  deliveryFee,
  tipAmount,
}) => {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView className={styles.orderDetails} rounded>
      <FView h={'6rem'} bg={themeColors.background} row alignCenter>
        {CloseButtonElement}
        <FView pr={'5rem'} fill>
          <FText h5 backgroundText textCenter>{`Order #${orderNumber}`}</FText>
        </FView>
      </FView>
      <FView p="2rem">
        <Space h2 />
        {OrderStatusElement}
        <Space h3 />
        <div>
          {ProductListElement}
          {RewardListElement}
          {DiscountElement}
          {AddedChargesElement}
        </div>
        <FView mv={'1rem'} h={1} bg={themeColors.lightGrey} />
        <div className={styles.column}>
          <div className={styles.productItem}>
            <FText h7 grey bold>
              SubTotal:
            </FText>
            <FText h7 grey bold>{`$${subTotalFormatted}`}</FText>
          </div>
          {deliveryFee > 0 && (
            <div className={styles.productItem}>
              <FText h7 grey bold>
                Delivery Fee:
              </FText>
              <FText h7 grey bold>{`$${deliveryFee.toFixed(2)}`}</FText>
            </div>
          )}
          <div className={styles.productItem}>
            <FText h7 grey bold>
              GST (5%):
            </FText>
            <FText h7 grey bold>{`$${taxFormatted}`}</FText>
          </div>
          {tipAmount > 0 && (
            <div className={styles.productItem}>
              <FText h7 grey bold>
                Tip:
              </FText>
              <FText h7 grey bold>{`$${tipAmount.toFixed(2)}`}</FText>
            </div>
          )}
          <FView mv={'1rem'} h={1} bg={themeColors.lightGrey} />
          <div className={styles.productItem}>
            <FText h7 grey bold>
              Total:
            </FText>
            <FText h7 grey bold>{`$${totalFormatted}`}</FText>
          </div>
        </div>
      </FView>
    </FView>
  )
}

const styles = {
  orderDetails: css({
    width: '50rem',
    maxWidth: '100vw',
    backgroundColor: COLOR.white,
    minHeight: '50rem',
  }),
  column: css({
    width: '60rem',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
  }),
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
}

export default OrderDetailsView
