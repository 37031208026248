import React from 'react'
import { css } from 'emotion'
import { MdClose, MdSearch, MdPlace } from 'react-icons/md'
import { IoIosCloseCircle } from 'react-icons/io'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import * as utils from 'f-utils'
import { ThemeContext } from 'f-web-app'
import { dispatch } from 'f-web-app/store'
import { COLOR } from 'f-web-app/styles'
import { FView, FButton, FText, FModal, FInput } from 'f-web-app/components'

export default class DeliveryModal extends React.Component {
  state = {
    isAddressSelected: !!this.props.deliveryAddress,
    formattedAddress: this.props.deliveryAddress,
    deliveryUnit: this.props.deliveryUnit,
    deliveryInstructions: this.props.deliveryInstructions,
    address: '',
    updating: false,
  }
  aptSuiteRef = React.createRef()
  deliveryInstructionsRef = React.createRef()
  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.setState({ isAddressSelected: false, formattedAddress: '', deliveryUnit: '', deliveryInstructions: '' })
    }
  }
  render() {
    const { onRequestClose, isOpen } = this.props
    return (
      <FModal isOpen={isOpen} closeModal={() => onRequestClose()}>
        <ThemeContext.Consumer>
          {({ themeColors, Components }) => (
            <FView w={600} maxWidth="100vw" h="65rem" maxHeight="calc(100vh - 100px)" bg={themeColors.white}>
              <FView h={'7rem'} justifyCenter>
                <FView row alignCenter p={'2rem'}>
                  <FButton onClick={() => onRequestClose()}>
                    <MdClose color={themeColors.backgroundText} size={30} />
                  </FButton>
                  <FView pr={19} fill center>
                    <FText h6 bold backgroundText>
                      ENTER ADDRESS
                    </FText>
                  </FView>
                </FView>
              </FView>
              <FView fill bg={themeColors.white} overflowY="auto">
                <FView h={this.state.isAddressSelected ? 80 : '100%'}>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this._handleOnAddressChange}
                    onSelect={this._handleAddressSelect}
                    searchOptions={{
                      componentRestrictions: { country: ['ca'] },
                      types: ['address'],
                    }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <FView className={classes.container}>
                        <FView
                          mb={'2.5rem'}
                          mh="2.5rem"
                          rounded
                          style={{ boxShadow: 'none', border: '1px solid ' + themeColors.grey50, height: '6.5rem' }}
                          row
                        >
                          <FView size={'6.3rem'} center>
                            <MdSearch color={themeColors.lightGrey} size={30} />
                          </FView>
                          <FView fill>
                            <FInput
                              style={{ boxShadow: 'none', border: 'none', height: '6.3rem' }}
                              {...getInputProps()}
                              placeholder="Search for an address"
                              onKeyPress={e => {
                                e.target.keyCode === 13 && e.preventDefault()
                              }}
                            />
                          </FView>
                          {!!this.state.address && (
                            <FButton
                              onClick={() =>
                                this.setState({
                                  address: '',
                                  isAddressSelected: false,
                                  formattedAddress: '',
                                  deliveryUnit: '',
                                  deliveryInstructions: '',
                                })
                              }
                            >
                              <FView size={'6.3rem'} center>
                                <IoIosCloseCircle color={themeColors.lightGrey} size={30} />
                              </FView>
                            </FButton>
                          )}
                        </FView>
                        <FView bg="#EDEDED" h={15} />
                        {loading && (
                          <FView cursor="pointer" h={'5.3rem'} justifyCenter pl={'6rem'}>
                            <FText h7 grey bold>
                              Loading...
                            </FText>
                          </FView>
                        )}
                        {suggestions.map(suggestion => (
                          <div className={classes.placeItem} {...getSuggestionItemProps(suggestion)}>
                            <FView size={'5.4rem'} center>
                              <MdPlace color={themeColors.lightGrey} size={30} />
                            </FView>
                            <FText h7 grey bold>
                              {suggestion.description}
                            </FText>
                          </div>
                        ))}
                        {this.state.isAddressSelected && (
                          <>
                            <FView p={15} pl={'2.5rem'} bg={themeColors.white}>
                              <FText h7 black bold>
                                DELIVERING TO:
                              </FText>
                              <FView size={'2rem'} />
                              <FText h7 grey bold>
                                {this.state.formattedAddress}
                              </FText>
                            </FView>
                            <FView bg="#EDEDED" h={15} />
                            <FView mh={'2.5rem'} h={93} bg={themeColors.white}>
                              <FView mt={'1.5rem'} mb={'0.6rem'}>
                                <FText bold black h7>
                                  Apt/Suite:
                                </FText>
                              </FView>
                              <FView w="100%">
                                <FInput
                                  value={this.state.deliveryUnit}
                                  onChange={e => this.setState({ deliveryUnit: e.target.value })}
                                  placeholder="Optional"
                                  maxLength={10}
                                  className={classes.textInput}
                                  ref={this.aptSuiteRef}
                                />
                              </FView>
                              <FView size={'1rem'} />
                              <FView mt={'1.5rem'} mb={'0.6rem'}>
                                <FText bold black h7>
                                  Delivery Instructions:
                                </FText>
                              </FView>
                              <FView w="100%">
                                <FInput
                                  value={this.state.deliveryInstructions}
                                  onChange={e => this.setState({ deliveryInstructions: e.target.value })}
                                  placeholder="Optional"
                                  maxLength={100}
                                  className={classes.textInput}
                                  ref={this.deliveryInstructionsRef}
                                />
                              </FView>
                            </FView>
                          </>
                        )}
                      </FView>
                    )}
                  </PlacesAutocomplete>
                </FView>
              </FView>

              <FView selfCenter w={250} mv={'2.5rem'}>
                {!this.state.isAddressSelected ? (
                  <FButton onClick={() => onRequestClose()}>
                    <Components.PaintedButtonView label="CANCEL" grey />
                  </FButton>
                ) : (
                  <FButton
                    disabled={this.state.updating}
                    onClick={() => {
                      this.setState({ updating: true })
                      const formattedAddress = this.state.formattedAddress
                      dispatch.user
                        .setDeliveryAddress({
                          deliveryAddress: formattedAddress,
                          deliveryUnit: this.aptSuiteRef.current.value,
                          deliveryInstructions: this.deliveryInstructionsRef.current.value,
                        })
                        .then(() => {
                          this.setState({
                            address: '',
                            isAddressSelected: false,
                            formattedAddress: '',
                            deliveryUnit: '',
                            deliveryInstructions: '',
                          })
                          onRequestClose(formattedAddress)
                        })
                        .finally(() => this.setState({ updating: false }))
                    }}
                  >
                    <Components.PaintedButtonView label="DONE" disabled={this.state.updating} />
                  </FButton>
                )}
              </FView>
            </FView>
          )}
        </ThemeContext.Consumer>
      </FModal>
    )
  }
  _handleOnAddressChange = address => {
    this.setState({
      address,
      isAddressSelected: false,
    })
  }
  _handleAddressSelect = address => {
    if (address) {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const { restaurantLatLng, deliveryDistance } = this.props
          const distance = utils.latlngDistance(lat, lng, restaurantLatLng.lat, restaurantLatLng.lng)
          if (distance > deliveryDistance) {
            dispatch.notification.setMessage({
              message: `Sorry we only deliver in a ${deliveryDistance}km radius from our restaurant.`,
              level: 'error',
            })
          } else {
            this.setState({
              address,
              formattedAddress: address,
              isAddressSelected: true,
              deliveryUnit: '',
              deliveryInstructions: '',
            })
          }
        })
    }
  }
}

const classes = {
  container: css({
    position: 'relative',
  }),
  placeItem: css({
    height: '5.3rem',
    padding: '1rem',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    paddingHorizontal: '1rem',
    flexDirection: 'row',
  }),
  textInput: css({
    boxShadow: 'none',
    border: '1px solid ' + COLOR.grey50,
    height: '4rem',
  }),
}
