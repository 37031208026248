export * from 'f-core/src/images'
export * from 'f-web-app/images'

// Mobile Ad
export const mobileAd1 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/mobileAd1.png'
export const mobileAd2 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/mobileAd2.png'
export const mobileAdDesktop =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/mobileAdDesktop.png'

// ABOUT US
export const aboutUsLabel =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsLabel.png'
export const aboutUsSakura =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsSakura.png'
export const aboutUsInterior =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsInterior.png'
export const aboutUsDescription =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsDescription.png'
export const aboutUsMountainMobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsMountainMobile.png'
export const aboutUsMountain =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/aboutUsMountain.png'

// REWARD
export const pointsMeter =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v4/foodly/rewardBG.png'
export const rewardBorder =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardBorder.png'

// MENU
export const categoryFrame1 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame1.png'
export const categoryFrame2 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame2.png'
export const categoryFrame3 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame3.png'

export const categoryFrame1Mobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame1Mobile.png'
export const categoryFrame2Mobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame2Mobile.png'
export const categoryFrame3Mobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/categoryFrame3Mobile.png'
