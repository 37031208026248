import React, { useContext, useEffect } from 'react'
import { dispatch } from 'f-web-app/store'
import { FView, FText } from 'f-web-app/components'
import useForceUpdate from 'use-force-update'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'f-web-app'

export default function StoreClosedBanner() {
  const { themeColors } = useContext(ThemeContext)
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    const internvalID = setInterval(() => {
      forceUpdate()
    }, 1000)
    return () => clearInterval(internvalID)
  }, [forceUpdate])
  const isStoreOpen = useSelector(dispatch.restaurant.getIsStoreOpen)
  return isStoreOpen ? null : (
    <FView p={'1rem'} mb="1rem" mh={7} w="100%" bg={themeColors.warning} rounded>
      <FText h6 white bold textCenter>
        ORDERING IS CLOSED
      </FText>
    </FView>
  )
}
