import React, { useContext } from 'react'
import { FView, FText } from 'components'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { galleryTitle, gallery01, gallery02, gallery03, gallery04, gallery05, gallery06 } from 'images'
import { mobileAd1, mobileAd2, mobileAdDesktop } from 'images'
import { aboutUsLabel, aboutUsSakura, aboutUsInterior, aboutUsDescription, aboutUsMountain } from 'images'
import { ThemeContext } from 'f-web-app'

export default function HomeView({ locationId }) {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  const { Home, Components, themeColors } = useContext(ThemeContext)
  return (
    <FView bg={themeColors.background} overflowX={'hidden'}>
      <Components.PageHeader />
      <Home.Hero appDownloadLink={'http://onelink.to/foodlydemo'} title="“THE HIDDEN PEARL OF PORT COQUITLAM”" />
      <FView m={15} selfCenter maxWidth={1224} mr={isMinLG ? -200 : 0} mb={isMinLG ? 97 : 15}>
        <img src={galleryTitle} alt="gallery title" style={{ objectFit: 'contain', width: '100%' }} />
      </FView>
      <Home.Gallery images={[gallery01, gallery02, gallery03, gallery04, gallery05, gallery06]} />
      {isMinMD ? (
        <FView alignCenter mr={'2rem'} mt={'15rem'}>
          <img
            src={mobileAdDesktop}
            alt="mobile ad"
            style={{ objectFit: 'contain', width: 1024, maxWidth: '100%', height: 'auto' }}
          />
        </FView>
      ) : (
        <>
          <FView m={15}>
            <FText h5 bold heading black textCenter>
              WE ARE NOW TAKING ONLINE ORDERS
            </FText>
          </FView>
          <img src={mobileAd1} alt="mobile ad1" style={{ objectFit: 'contain', width: '100%', height: 'auto' }} />
          <FView m={15}>
            <FText h7 heading black textCenter>
              COLLECT POINTS AND REDEEM FREE ITEMS
            </FText>
            <FView size={11} />
            <img src={mobileAd2} alt="mobile ad2" style={{ objectFit: 'contain', width: '100%' }} />
          </FView>
        </>
      )}
      {isMinLG ? (
        <FView selfCenter w={1024} maxWidth={'100%'}>
          <img
            src={aboutUsLabel}
            alt="about us label"
            style={{ objectFit: 'contain', height: 200, alignSelf: 'flex-start' }}
          />
          <img src={aboutUsSakura} alt="about us sakura" style={{ position: 'absolute', right: 0 }} />
          <FView row mt={48}>
            <img
              src={aboutUsInterior}
              alt="about us interior"
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            />
            <FView size={13} />
            <img
              src={aboutUsDescription}
              alt="about us description"
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            />
          </FView>
        </FView>
      ) : (
        <FView mt={25}>
          <FView m={15}>
            <img src={aboutUsLabel} alt="about us label" style={{ objectFit: 'contain', width: '40vw' }} />
            <img
              src={aboutUsInterior}
              alt="about us interior"
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            />
            <FView size={15} />
            <img
              src={aboutUsDescription}
              alt="about us description"
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            />
          </FView>
        </FView>
      )}
      <img
        src={aboutUsMountain}
        alt="about us mountain"
        style={{ objectFit: 'contain', width: '100%', height: 'auto', marginTop: '5vw' }}
      />
      <FView size={'5vw'} maxSize={44} />
      <Home.ContactUs
        appStoreUrl={'https://play.google.com/store/apps/details?id=tech.foodly.demo'}
        googlePlayUrl="https://play.google.com/store/apps/details?id=tech.foodly.demo"
      />
      <FView size={'12vw'} maxSize={72} />
      <Home.Footer />
    </FView>
  )
}
