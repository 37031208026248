import React, { useContext } from 'react'
import { FText, FView, Space } from 'components'
import { ThemeContext } from 'f-web-app'
import { moment } from 'f-utils'

const OrderCompleteView = ({
  orderNumber,
  OrderStatusElement,
  status,
  orderType,
  completionTime,
  estimatedCompletionTime,
  pointsEarned,
}) => {
  const { themeColors } = useContext(ThemeContext)

  return (
    <FView fill maxWidth="100%" bg={themeColors.white} p={'2rem'} rounded shadow25>
      <FText h4 cardText textCenter>
        Order {`#${orderNumber ? orderNumber : ''}`}
      </FText>
      <Space h3 />
      {OrderStatusElement}
      <Space h3 />
      <FText h7 bold cardText textCenter>
        {status === 'Done'
          ? orderType === 'Delivery'
            ? 'Your order is prepared and out for delivery!'
            : 'Your order is ready for pickup!'
          : status === 'New'
          ? 'Your order is being confirmed by the restaurant...'
          : `Your order will be ready at ${moment(completionTime || estimatedCompletionTime).format('LT')}. Thank you!`}
      </FText>
      <Space h2 />
      {pointsEarned > 0 && (
        <FText h7 bold primary textCenter>
          {`Congrats! You earned ${pointsEarned} points!`}
        </FText>
      )}
    </FView>
  )
}

export default OrderCompleteView
