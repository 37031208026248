export const colors = {
  BG: '#f9f3ee',
  PRIMARY: '#ee5253',
  DISABLED: '#757575',
  LIGHT_DISABLED: '#BDBDBD',
  LIGHT_TEXT: '#aaa',
  XLIGHT_TEXT: '#eee',
  LIGHT_GREY: '#ddd',
  ALERT: '#d32f2f',
  LINK: '#4A90E2',

  LIGHT_BG: '#f9f3ee',
  GREY_MAIN: '#4A4A4A',
}

// Shared between mobile, web, and admin
export const TYPES = {
  ANALYTICS: {
    ERROR: 'ANALYTICS/ERROR',
    SCREEN_CHANGE: 'ANALYTICS/SCREEN_CHANGE', // For non-redux react-navigation
    LOGIN_SUCCESS: 'ANALYTICS/LOGIN_SUCCESS',
    LOGIN_FAIL: 'ANALYTICS/LOGIN_FAIL',
    LOGOUT_SUCCESS: 'ANALYTICS/LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'ANALYTICS/LOGOUT_FAIL',
    REGISTER_SUCCESS: 'ANALYTICS/REGISTER_SUCCESS',
    REGISTER_FAIL: 'ANALYTICS/REGISTER_FAIL',
    ORDER_COMPLETE: 'ANALYTICS/ORDER_COMPLETE',
    ORDER_FAILED: 'ANALYTICS/ORDER_FAILED',

    // ADMIN
    USER_PROPERTIES_UPDATE: 'ANALYTICS/USER_PROPERTIES_UPDATE',
    START_ALERT: 'ANALYTICS/START_ALERT',
    STOP_ALERT: 'ANALYTICS/STOP_ALERT',
    STATUS_CHANGE: 'ANALYTICS/STATUS_CHANGE',
    MENU_UPDATE: 'ANALYTICS/MENU_UPDATE',
    CATEGORY_UPDATE: 'ANALYTICS/CATEGORY_UPDATE',
    CATEGORY_DELETE: 'ANALYTICS/CATEGORY_DELETE',
    CATEGORY_ORDER_UPDATE: 'ANALYTICS/CATEGORY_ORDER_UPDATE',
    PRODUCT_ORDER_UPDATE: 'ANALYTICS/PRODUCT_ORDER_UPDATE',
    PRODUCT_OPTION_ADD: 'ANALYTICS/PRODUCT_OPTION_ADD',
    PRODUCT_OPTION_DELETE_SUCCESS: 'ANALYTICS/PRODUCT_OPTION_DELETE_SUCCESS',
    PRODUCT_OPTION_DELETE_FAIL: 'ANALYTICS/PRODUCT_OPTION_DELETE_FAIL',
    PRODUCT_UPDATE: 'ANALYTICS/PRODUCT_UPDATE',
    PRODUCT_DELETE_SUCCESS: 'ANALYTICS/PRODUCT_DELETE_SUCCESS',
    PRODUCT_DELETE_FAIL: 'ANALYTICS/PRODUCT_DELETE_FAIL',
    REWARDS_UPDATE: 'ANALYTICS/REWARDS_UPDATE',
    ANALYTICS_APPLY: 'ANALYTICS/ANALYTICS_APPLY',
    ORDERING_IS_OPEN_UPDATE: 'ANALYTICS/ORDERING_IS_OPEN_UPDATE',
    WAIT_TIME_UPDATE: 'ANALYTICS/WAIT_TIME_UPDATE',
    HOURS_UPDATE: 'ANALYTICS/HOURS_UPDATE',
  },
}
