// FIREBASE FOR CLIENT (MOBILE AND WEB)
const firebase = require('firebase/app')
const storageRef = require('firebase/firestore')
require('firebase/auth')
require('firebase/performance')
const isMobile = false

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
firebase.initializeApp(config)

process.env.REACT_APP_ENVIRONMENT === 'dev' && firebase.firestore.setLogLevel('debug')

const firestore = firebase.firestore()
const firebaseAuth = firebase.auth()
const perf = firebase.performance()

export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider

const db = firestore

const getRestaurantsCollection = () => firestore.collection('Restaurants')

const getRestaurantDoc = ({ restaurantId }) => firestore.collection('Restaurants').doc(restaurantId)
const getRestaurantLocationsCollection = ({ restaurantId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
const getRestaurantLocationDoc = ({ restaurantId, locationId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)

const getRestaurantOrdersCollection = ({ restaurantId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')

const getRestaurantOrderDoc = ({ restaurantId, orderId }) =>
  firestore
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .doc(orderId)

const getUsersCollection = () => firestore.collection('Users')

const getUserDoc = ({ userId }) => firestore.collection('Users').doc(userId)
const getUserRestaurantDoc = ({ userId, restaurantId }) =>
  firestore
    .collection('Users')
    .doc(userId)
    .collection('Restaurants')
    .doc(restaurantId)

const DELETE_FIELD_VALUE = firebase.firestore.FieldValue.delete()
const FieldValue = firebase.firestore.FieldValue

export {
  firebaseAuth,
  storageRef,
  isMobile,
  perf,
  // Firestore
  db,
  FieldValue,
  DELETE_FIELD_VALUE,
  getRestaurantsCollection,
  getRestaurantDoc,
  getRestaurantLocationsCollection,
  getRestaurantLocationDoc,
  getRestaurantOrdersCollection,
  getRestaurantOrderDoc,
  getUsersCollection,
  getUserDoc,
  getUserRestaurantDoc,
}
