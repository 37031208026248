import { COLOR } from 'f-web-app/styles'
export { default as Payment } from './Payment'

export const cardNumberInputProps = {
  placeholder: 'Card Number',
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const expiryInputProps = {
  placeholder: 'Expiry',
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const CVCInputProps = {
  placeholder: 'CVC',
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}
