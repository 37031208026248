import { COLOR as fColors } from 'f-web-app/styles'

export * from 'f-web/src/styles'

export const COLOR = {
  ...fColors,
  // Custom Colors
}

export const TEXT = {
  // Custom Text
}
